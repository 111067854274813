<template>
  <TaskDetailItemEditCard confirmation-message="Taaknaam werd succesvol gewijzigd" title="Bewerk taaknaam"
    update-store-action="tasksModule/updateTaskName"
    :update-store-action-payload="{ taskName: taskName, projectId: projectId, clientId: clientId, taskId: taskId }"
    :projectId="projectId" :clientId="clientId" :taskId="taskId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="taskName" persistent-placeholder label="Titel" type="text" />
        </v-col>
      </v-row>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"


export default {
  name: "TaskDetailEditTaskName",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      taskName: null,
      promises: [
        this.$store.dispatch('tasksModule/fetchTaskName', { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
          .then((taskName) => this.taskName = taskName)
      ]
    }
  },
  components: {
    TaskDetailItemEditCard
  },
}
</script>